import Container from '@gtg/components/container'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import LogoUrl from '../images/lenzbox-logo.svg'
import { color } from '../styles/theme'
import Box from '@gtg/components/box'

const StyledHeader = styled.section`
  background: ${color.coal50};
  border-bottom: 2px solid ${color.coal200};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const Logo = styled.div`
  background: url(${LogoUrl}) no-repeat left center;
  background-size: contain;
  width: 100%;
  height: 30px;
`

const Header = props => {
  return (
    <StyledHeader>
      <Box pyMax="md">
        <Container>
          <Link to="/">
            <Logo />
          </Link>
        </Container>
      </Box>
    </StyledHeader>
  )
}

export default Header
