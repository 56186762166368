import Container from '@gtg/components/container'
import React from 'react'
import styled from 'styled-components'
import Box from '@gtg/components/box'
import Footer from '../components/footer'
import GlobalWrapper from '../components/globalWrapper'
import Header from '../components/header'
import { color } from '../styles/theme'

const Section = styled.section`
  background: ${color.coal50};
`

const DataPrivacyPage = () => {
  return (
    <GlobalWrapper>
      <Header />
      <Section>
        <Box pyMax="lg">
          <Container>
            <h1>Datenschutz</h1>
            <h2>Ihr Datenschutz ist uns wichtig</h2>
            <p>
              Im Rahmen der von uns erbrachten Dienstleistungen werden von
              Lenzbox Daten erfasst. Wenn Sie Lenzbox personenbezogene Daten
              übertragen, erteilen Sie uns damit die Zustimmung, diese zu
              verwenden. Persönliche Daten von Besuchern werden mit
              größtmöglicher Sorgfalt behandelt und geschützt.
            </p>
            <h2>Verwendung von Cookies</h2>
            <p>
              Cookies sind kleine Informationsdateien, die eine Website auf
              Ihrem Computer hinterlässt. Lenzbox verwendet Cookies für die
              Speicherung Ihrer persönlichen Einstellungen und Log-in-Daten. Die
              Verwendung von Cookies muss von Ihnen selbst autorisiert werden.
            </p>
            <h2>Wie behandeln wir Ihre Daten</h2>
            <p>
              Wir behandeln Ihre personenbezogenen Daten streng vertraulich.
              Diese Daten werden von uns ausschliesslich zur Kontaktaufnahme mit
              Ihnen verwendet. Falls Sie dies nicht mehr in Anspruch nehmen
              möchten, können Sie sich jederzeit mit uns in Verbindung setzen.
            </p>
            <h2>Verwendung von Google™ Analytics</h2>
            <p>
              Diese Webseite verwendet Google™ Analytics, einen Webanalysedienst
              der Google Inc. (“Google”). Google Analytics verwendet sog.
              “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden
              und die eine Analyse der Benutzung der Website durch Sie
              ermöglichen. Die durch den Cookie erzeugten Informationen über
              Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse)
              werden an einen Server von Google in den USA übertragen und dort
              gespeichert. Google wird diese Informationen benutzen, um Ihre
              Nutzung der Website auszuwerten, um Reports über die
              Websiteaktivitäten für die Websitebetreiber zusammenzustellen und
              um weitere mit der Websitenutzung und der Internetnutzung
              verbundene Dienstleistungen zu erbringen. Sollten Sie keine
              Speicherung Ihrer Daten wünschen, installieren Sie sich bitte das
              von Google bereitgestellte Deaktivierungswerkzeug unter{' '}
              <a href="https://tools.google.com/dlpage/gaoptout">
                tools.google.com/dlpage/gaoptout
              </a>
            </p>
            Registrierung anonymer Besucherdaten
            <p>
              Auf der Website von Lenzbox werden allgemeine Besucherdaten und
              IP-Adressen erfasst, ohne dass diese die Besucher identifizieren.
              Dies dient der Optimierung von Inhalten und Gestaltung der
              Website, sowie der Erstellung von Analysen und Berichten.
            </p>
            <h2>Änderung der Datenschutzerklärung</h2>
            <p>
              Lenzbox behält sich das Recht vor, seine Datenschutzerklärung in
              gewissen Intervallen zwecks Anpassung an gesetzliche und sonstige
              Entwicklungen zu aktualisieren und zu überarbeiten. Damit Sie
              stets über Änderungen informiert sind, empfehlen wir Ihnen daher
              regelmässiges Einsehen der Datenschutzerklärung.
            </p>
          </Container>
        </Box>
      </Section>
      <Footer />
    </GlobalWrapper>
  )
}

export default DataPrivacyPage
